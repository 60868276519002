const slider = [
  {
    id: 0,
    content: "AMAZING FROM Lukani",
    image: "https://htmldemo.net/lukani/lukani/assets/img/slider/slider4.jpg",
    title: `cactus`,
    title2: "decoration",
    desc: "Discount 20% Off For Lukani Members",
  },
  {
    id: 1,
    content: "AMAZING FROM Lukani",
    image: "https://htmldemo.net/lukani/lukani/assets/img/slider/slider5.jpg",
    title: `lukani `,
    title2: "houseplants",
    desc: "Discount 20% Off For Lukani Members",
  },
  {
    id: 2,
    content: "AMAZING FROM Lukani",
    image: "https://htmldemo.net/lukani/lukani/assets/img/slider/slider6.jpg",
    title: `outdoor `,
    title2: "the best choice",
    desc: "Discount 20% Off For Lukani Members",
  },
  {
    id: 3,
    content: "AMAZING FROM Lukani",
    image: "https://htmldemo.net/lukani/lukani/assets/img/slider/slider7.jpg",
    title: `BIG SALE  `,
    title2: " NEW ARRIVALS",
    desc: "Discount 20% Off For Lukani Members",
  },
  {
    id: 4,
    content: "AMAZING FROM Lukani",
    image: "https://htmldemo.net/lukani/lukani/assets/img/slider/slider8.jpg",
    title: `lukani  `,
    title2: "trending",
    desc: "Discount 20% Off For Lukani Members",
  },
  {
    id: 5,
    content: "AMAZING FROM Lukani",
    image: "https://htmldemo.net/lukani/lukani/assets/img/slider/slider9.jpg",
    title: `amazing  `,
    title2: " choice",
    desc: "Discount 20% Off For Lukani Members",
  },
];

export default slider;
