const chosen = [
  {
    index: 0,
    title: `Creative Design`,
    desc: "Erat metus sodales eget dolor consectetuer, porta ut purus at et alias, nulla ornare velit amet",
    icon: "https://htmldemo.net/lukani/lukani/assets/img/about/About_icon1.png",
  },
  {
    index: 1,
    title: `100% Money Back Guarantee`,
    desc: "Erat metus sodales eget dolor consectetuer, porta ut purus at et alias, nulla ornare velit amet",
    icon: "https://htmldemo.net/lukani/lukani/assets/img/about/About_icon2.png",
  },
  {
    index: 2,
    title: `Online Support 24/7`,
    desc: "Erat metus sodales eget dolor consectetuer, porta ut purus at et alias, nulla ornare velit amet",
    icon: "https://htmldemo.net/lukani/lukani/assets/img/about/About_icon3.png",
  },
];

export default chosen;
