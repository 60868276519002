const brands = [
  {
    index: 0,
    link: `#`,
    icon: "https://htmldemo.net/lukani/lukani/assets/img/brand/brand1.png",
  },
  {
    index: 1,
    link: `#`,
    icon: "https://htmldemo.net/lukani/lukani/assets/img/brand/brand2.png",
  },
  {
    index: 2,
    link: `#`,
    icon: "https://htmldemo.net/lukani/lukani/assets/img/brand/brand3.png",
  },
  {
    index: 3,
    link: `#`,
    icon: "https://htmldemo.net/lukani/lukani/assets/img/brand/brand4.png",
  },
  {
    index: 4,
    link: `#`,
    icon: "https://htmldemo.net/lukani/lukani/assets/img/brand/brand5.png",
  },
];

export default brands;
